<template>
  <div class="BundleProductCard">
    <div class="w-full aspect-product flex desk:flex-row">
      <div
        class="w-full h-full relative"
      >
        <div
          v-if="product.quantity && product.quantity > 1"
          class="type-headline-xl m-auto bg-brightRed w-50 h-50 rounded-full text-lightest flex items-center align-center leading-single absolute top-8 right-8"
        >
          <span class="w-full text-center">{{ product.quantity }}x</span>
        </div>
        <div
          v-else-if="showIcon"
          class="h-full absolute top-[45%] right-[-20px] z-40"
        >
          <div class="bg-brightRed h-32 w-32 flex items-center justify-center">
            <img
              src="/icons/plus-inv.svg"
              class="w-24 h-24 inline-block"
              alt=""
            >
          </div>
        </div>
        <div
          v-if="!product.image"
        >
          <img
            src="/icons/no-image.svg"
            class="aspect-product"
            alt="no image"
          >
        </div>
        <div
          v-else
          class="w-full h-full flex items-center justify-center"
        >
          <nuxt-img
            v-if="product.image"
            :src="product.image"
            provider="norce"
            preset="plpimage"
            class="w-[80%] h-[80%] object-cover"
            :alt="product.name"
          />
        </div>

        <div class="imageOverlay absolute top-0 left-0 w-full h-full" />
      </div>
    </div>
    <div class="p-12 desk:px-8 desk:pt-16 pb-24">
      <div class="type-sm-medium"><span v-if="product.quantity && product.quantity > 1">{{ product.quantity }}x </span>{{ product.name }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { StructureArticleProduct } from '~/constants/types/algolia';
import { useGlobalContentStore } from '~/store/globalContent';

import useLinkReplacer from '~/composeables/useLinkReplacer';
const props = defineProps<{
  product: StructureArticleProduct,
  showIcon: boolean,
}>();

const { productUrl } = useLinkReplacer();
const link = computed(()=> {
  return productUrl(props.product.url);
});
</script>

<style scoped lang="postcss">
.imageOverlay {
  background: transparent radial-gradient(closest-side at 50% 50%, #FFFFFF00 0%, #0000000A 100%) 0% 0% no-repeat padding-box;
}
</style>

